<template>
  <div id="div-dropzone-form">
    <div class="container">
      <section class="enrollment-section pt-0">
        <div class="section-name">
        </div>
        <div class="section-content">
          <div v-show="state.members.length == 0">
            <form action="#" ref="dropzoneForm" class="dropzone-form">
              <div data-behavior="choose-file">
                Drag a .csv file here to upload<br />or <em>browse</em> to select a file
              </div>
            </form>
          </div> 
        </div>
      </section>
    </div>

    <section class="enrollment-footer">
      <div class="container">
        <div class="enrollment-section">
          <div class="section-content" v-if="state.renderTrigger > -1">            
            <div v-if="isUploading()">
              <button disabled class="btn btn-primary">Uploading...</button>
            </div>

            <div v-else-if="canUpload()">
              <a href="javascript:;" @click="upload()" class="btn btn-primary submit-order">Upload Employees</a>
            </div>

            <div class="enrollment-footer-actions" v-else-if="isUploadComplete()">
              <slot></slot>
            </div>

            <div>
              <csv-member-import-problems />
              <csv-member-table/>
            </div>
          </div>          
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { ref, onMounted, reactive } from 'vue'
import CsvMemberImportProblems from './member-import-problems'
import CsvMemberTable from './member-table'
import Dropzone from 'dropzone'
import Papa from 'papaparse'
import Store from './store'
import Member from './member'

export default {
  props: { 
    organizationId: { 
      type: String, 
      required: true, 
    },
    formAction: { 
      type: String, 
      required: true, 
    }
  },
  components: {
    CsvMemberTable,
    CsvMemberImportProblems
  },
  setup(props) {
    const state = reactive(Store.state);
    const dropzone = ref(null);
    const dropzoneForm = ref(null);
    const interval = ref(null);
    const pollInterval = 2000;

    const upload = (url) => {
      state.renderTrigger++;
      dropzone.value.options.url = props.formAction;
      Store.uploadInProgress();
      dropzone.value.processQueue();
    };
    
    const poll = () => {
      if (Store.state.problems == null && Store.state.memberImport != null) {
        $.getJSON(Store.pollUrl(), { dataType: 'json' }, (response) => {
          if (response.problems != null) {
            Store.state.problemsProcessed = true;
            Store.state.problems = response.problems;
            state.renderTrigger++;
          }
        });
      }
    };

    const canUpload = () => {
      return state.members.length > 0 && !isUploadComplete();
    }

    const isUploading = () => {
      return Store.state.uploadStatus == 'in-progress' || uploadIsErrorFree();
    }

    const uploadIsErrorFree = () => {
      return isUploadComplete() && !state.problemsProcessed;
    }

    const isUploadComplete = () => {
      return state.uploadStatus == 'success';
    }

    onMounted(() => {
      interval.value = setInterval(() => { poll(); }, pollInterval);
      let csrfTokenElement = document.querySelector('meta[name="csrf-token"]');

      dropzone.value = new Dropzone(dropzoneForm.value, {
        clickable: '[data-behavior="choose-file"]',
        autoProcessQueue: false,
        previewsContainer: false,
        headers: {
          'X-CSRF-Token': csrfTokenElement ? csrfTokenElement.getAttribute('content') : null,
        },
      });

      dropzone.value.on('addedfile', onFileAdded);

      dropzone.value.on('queuecomplete', () => {
        Store.uploadSuccess();
      });

      dropzone.value.on('success', (file, xhr) => {
        xhr.member_import.organization_id = props.organizationId;
        Store.setMemberImport(xhr.member_import);
      });
    });

    const onFileAdded = (file) => {
      Store.setFile(file);
      Papa.parse(file, {
        header: true,
        step: (row) => {
          const member = new Member(row.data);
          if (member.isValid()) {
            Store.addMember(member);
          }
          Store.addRow(row);
        },
        complete: () => {
          state.renderTrigger++; // Trigger re-render by incrementing the counter
        },
      });
    }

    return {
      state,
      dropzoneForm,
      upload,
      isUploading,
      canUpload,
      uploadIsErrorFree,
      isUploadComplete,
      upload,
    };
  },
}
</script>

<style scoped lang="scss">

@import "../../assets/stylesheets/_colors";


</style>
