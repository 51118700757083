import { createApp, ref, onMounted, onBeforeMount } from 'vue';

const PaymentConfirmer = {
  setup() {
    const asyncUrl = ref(null);
    const redirectUrl = ref(null);
    const pollInterval = ref(2000);

    const poll = () => {
      fetch(asyncUrl.value)
        .then(response => response.json())
        .then(response);
    };

    const response = (resp) => {
      const order = resp;
      if (!order.processed_at) {
        setTimeout(poll, pollInterval.value);
        return;
      }
      window.location = redirectUrl.value;
    };

    onBeforeMount(() => {
      const el = document.getElementById('payment-confirmer');
      asyncUrl.value = el.getAttribute('async-url');
      redirectUrl.value = el.getAttribute('redirect-url');
    });

    onMounted(() => {
      poll();
    });

    return {
      asyncUrl,
      redirectUrl,
      pollInterval
    };
  }
};

export default PaymentConfirmer;
