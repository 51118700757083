/* eslint no-console: 0 */
import { createApp } from 'vue'
import BatchApp from '../batch-member-importer/app.vue'
import CsvTemplateApp from '../csv-template/app.vue'
import AsyncCode from '../async-code/app.vue'
import PaymentConfirmer from '../payment/confirmer.js'


document.addEventListener('turbolinks:load', () => {
  if(document.getElementById('async-code')){
    createApp(AsyncCode).mount('#async-code');
  }

  if(document.getElementById('batch-member-importer')){
    createApp(BatchApp).mount('#batch-member-importer');
  }

  if(document.getElementById('csv-templates')){
    createApp(CsvTemplateApp).mount('#csv-templates');
  }  

  const el = document.getElementById('payment-confirmer');
  if (el) {
    createApp(PaymentConfirmer).mount('#payment-confirmer');
  }
})
