<script>
import CsvDropzone from './dropzone'

export default {
  el: '#batch-member-importer',
  props: ['formAction'],
  components: {
    CsvDropzone
  }
}
</script>
