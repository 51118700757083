<template>
  <p v-if="renderTrigger < 0">{{ "member-import-problems: "+renderTrigger }}</p>

  <div class="member-container" v-if="errorFreeUpload()">
    <header>
      <div spacer></div>
      <span class="header-message" v-if="membersEquivalentToRows()"> All employees can be uploaded successfully</span>
      <span class="header-message" v-else> Only {{ members.length }} out of {{ rows.length - 1 }} member(s) can be uploaded successfully </span>
      <a href="javascript:;" @click="reset()" class="close-btn">&times;</a>
    </header>
    <div class="member-table">
      <div class="tr header">
        <div class="cell">First Name</div>
        <div class="cell">Middle Name</div>
        <div class="cell">Last Name</div>
        <div class="cell">Email</div>
        <div class="cell">Address</div>
        <div class="cell">External Member Id</div>
      </div>
      <div class="tr" v-for="member in members.slice(0, 5)">
        <div class="cell">{{ member.firstName }}</div>
        <div class="cell">{{ member.middleName }}</div>
        <div class="cell">{{ member.lastName }}</div>
        <div class="cell">{{ member.email }}</div>
        <div class="cell">{{ member.address }}</div>
        <div class="cell">{{ member.externalMemberId }}</div>
      </div>
      <footer v-if="members.length > 5">
        Plus {{ members.length - 5 }} more rows
      </footer>
    </div>
  </div>
</template>

<script>
import Store from './store'
import Member from './member'

export default {
  data: function() {
    return Store.state
  },
  methods: {
    reset: function() {
      Store.reset();
      this.renderTrigger++;
    },
    errorFreeUpload: function() {
      return this.members.length > 0 && this.problems == null
    },
    membersEquivalentToRows: function() {
      return this.members.length == this.rows.length - 1
    }
  }
}
</script>
